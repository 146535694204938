import S3 from 'react-aws-s3';

export async function UploadImageToS3WithReactS3(props, bucketName, dirName) {

    const config = {
        bucketName: bucketName,
        dirName: 'intranet/' + dirName, /* optional */
        region: 'ap-southeast-1',
        accessKeyId: 'AKIAZO3FTZZ2GXYBII5Z',
        secretAccessKey: 'VjtDVkSb6MmmYcrXFNe79qtL2B/Ogwsli5dmRnvV',
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }


    const ReactS3Client = new S3(config);
    /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

    /* This is optional */
    // const newFileName = 'test-file';
    let fileType = props
    let tempFile = new File([props], `${Date.now()}.jpeg`, { type: fileType.type });

    let tempFileName = Date.now()
    let uploadResult = await ReactS3Client
        .uploadFile(tempFile, tempFileName.name)
        .then(data => { return data })
        .catch(err => { return err })
    return uploadResult
    /**
     * {
     *   Response: {
     *     bucket: "myBucket",
     *     key: "image/test-image.jpg",
     *     location: "https://myBucket.s3.amazonaws.com/media/test-file.jpg"
     *   }
     * }
     */
}
