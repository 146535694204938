import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../../config";
import React, { useState } from "react";
import { getLocation } from "../../../utils/location";
import { getAllAttandanceByemCode } from "./attandanceSetting";

let initialState = {
    formSchemaTask: [],
    formGroupTask: [],
    setFormTask: null,
    formDataSetTask: null,
    isEnableEdit: false,
    selectedFormId: null,
    isLoading: true,
    formdata: null,
    selectedAnswer: null,
    formDataSetEdit: {},
    addbuttonDisable: false,
    answer: null,
    viewClockoutMenu: false,
    viewClockInData: false,
    ruleArray: [],
    attandanceDataSql: null,
    formInformation: null,
    shareLocation: false,

    locationData: null,
    locationShareTime: null,
    shopData: [],
    shareLocationReload: false,
    warningList: [],
    errorList: [],
    dataset: {},
    attandance:[],
    userData:null,

};





// export const initState = createAsyncThunk(
//     "markAttandance/initState",
//     async (data) => {

//         let returnObj = {
//             formInformation: data ? data : null,
//             formId: data.id,

//         }


//         let endPoint_formSchema = `${apiConfig.adminApi}/form/get-form-schema/${data.id}`;
//         const response_formSchema = await axios.get(endPoint_formSchema);
//         if (response_formSchema?.data) {
//             returnObj = {
//                 ...returnObj,
//                 schema: response_formSchema.data
//             }
//         }

//         let endPoint_formGroup = `${apiConfig.adminApi}/form/get-form-group/${data.id}`;
//         const response_formGroup = await axios.get(endPoint_formGroup);

//         if (response_formGroup?.data) {
//             returnObj = {
//                 ...returnObj,
//                 group: response_formGroup.data
//             }
//         }

//         let endPoint_formData = `${apiConfig.adminApi}/form/get-form-details/${data.id}`;
//         const response_formData = await axios.get(endPoint_formData);

//         if (response_formData?.data) {
//             returnObj = {
//                 ...returnObj,
//                 form: response_formData.data,
//                 formRule: response_formData?.data?.ruleSet ? response_formData.data.ruleSet : []
//             }
//         }

//         if (returnObj) {
//             return returnObj;
//         } else {
//             throw Error;
//         }
//     }
// );


export const initState = createAsyncThunk(
    "markAttandance/initState",
    async (data) => {
        try {
            let reqHeaders={
                headers: {
                  'X-emCode': localStorage.getItem('emCode')
                }
              }
            
            const [formSchema, formGroup, formData] = await Promise.all([
                axios.get(`${apiConfig.adminApi}/form/get-form-schema/${data.id}`,reqHeaders),
                axios.get(`${apiConfig.adminApi}/form/get-form-group/${data.id}`, reqHeaders),
                axios.get(`${apiConfig.adminApi}/form/get-form-details/${data.id}`, reqHeaders)
            ]);

            return {
                formInformation: data || null,
                formId: data.id,
                schema: formSchema.data,
                group: formGroup.data,
                form: formData.data,
                formRule: formData?.data?.ruleSet || []
            };
        } catch (error) {
            throw new Error(error.message);
        }
    }
);





export const removeStateReducer = createAsyncThunk(
    "markAttandance/removeStateReducer",
    async (data) => {

        return true
    }
);


export const getFormAnswerList = createAsyncThunk(
    "markAttandance/getFormAnswerList",
    async (formData) => {

        const token = localStorage.getItem('token')
        if (!token) {
            alert("Token not valid, Please login again");
           throw Error;     
        }

        let responsData = {};

        let endPointGetFormAnswer = `${apiConfig.adminApi}/form/get-form-answer`;

        let config = {
            headers: {
                authorization: 'Bearer ' + token,
                'X-emCode': localStorage.getItem('emCode')

            }
        }

        const response = await axios.post(endPointGetFormAnswer, formData, config);

        if (response?.data?.data) {
          const dataArray = response.data.data;
          let clockIndData = dataArray.find(
            (e) => e.data.database.main.clocked.status === "in"
          );

          console.log('search for in data only', clockIndData)

          // clocked in and not clocked out
          if (clockIndData) {
            responsData = {
              ...responsData,
              clockIndData: clockIndData,
            };

          } else {
            responsData = {
              ...responsData,
              clockIndData: null,
            };
          }
        }

        if (responsData) {
            return responsData;
        } else {
            throw Error;
        }

    }
);




export const shareLocationReducer = createAsyncThunk(
  "markAttandance/shareLocationReducer",
  async (obj) => {
    console.log(obj);
    let name = obj.name;
    let emp = obj.emp;
    let responsData = {};
    let endPoint_getShops;

    try {
      const locationremp = await getLocation();
      if (!locationremp) {
        throw new Error("Could not retrieve location");
      }

      if (!locationremp.latitude || !locationremp.longitude) {
        responsData = {
          ...responsData,
          shareLocation: false,
          error: locationremp.message,
        };
        return responsData;
      }

      const token = localStorage.getItem("token");
      console.log(token ? token : "no token");
      if (!token) {
        throw new Error("No Token Found, Please Login");
      }

      console.log("token found");
      endPoint_getShops = `${apiConfig.adminApi}/form/get-Tasks-Stores-by-location`;

      const config = {
        headers: {
          authorization: "Bearer " + token,
        },
      };

      let data = {
        formId: "69714c88-37f7-467b-9e19-4efade71afc7",
        userLocation: {
          latitude: locationremp.latitude,
          longitude: locationremp.longitude,
        },

        sorting: false,
        order: false,
        search: [
          {
            key: "Project",
            value: "GQ",
          },
        ],
        viewType: "participants",
        selectedPageParam: 1,
        rowPerRequest: 10,
        maxDistance: 1000,
        storesOnly: true,
      };
      const response_getShops = await axios.post(
        endPoint_getShops,
        data,
        config
      );
      if (
        !response_getShops ||
        !response_getShops.data ||
        !response_getShops.data.stores
      ) {
        alert("Could not retrieve shops");
      }

      const stores = response_getShops.data.stores;
      console.log("stores", stores);
      let storesList = [];
                    if (stores) {
                        stores.map((store) => {
                        let storeObj = {
                            Brand: store.data.database.main.brand_menu,
                            StoreID: store.data.database.main.Store_Code,
                            BranchCode : store.data.database.main.Branch_ID,
                            StoreName: store.data.database.main.Store_Name,
                            distance_in_km: store.data.database.main.distance_in_km ? store.distance_in_km : 0,
                        };

                        storesList.push(storeObj);
                      
                        });
                    }
                    console.log("storesList", storesList);
      responsData = {
        ...responsData,
        locationData: locationremp,
        showData: storesList,
        shareLocation: true,
      };

      responsData.userData = {
        EmployeeName_EN: name,
        EmployeeCode: emp,
      };
    } catch (error) {
      responsData = {
        ...responsData,
        error: error.message,
      };
    }
    console.log("responsData", responsData);
    return responsData;
  }
);


export const changeDataReducer = createAsyncThunk(
    "markAttandance/changeDataReducer",
    async (data) => {

        if (data) {
            return data;
        } else {
            throw Error;
        }

    }
);

export const changeDataEditAction = createAsyncThunk(
    "markAttandance/changeDataEditAction",
    async (data) => {

        let res = {

        }
        if (data?.database?.main?.clocked?.clockOut) {
            res = {
                ...res,
                enableEdit: true
            }

        } else {
            res = {
                ...res,
                enableEdit: false
            }
        }
        res = {
            ...res,
            data: data
        }

        if (res) {
            return res;
        } else {
            throw Error;
        }

    }
);

export const closeClockingModel = createAsyncThunk(
    "markAttandance/closeClockingModel",
    async (data) => {

        return true;
        if (true) {
            return data;
        } else {
            throw Error;
        }

    }
);


export const closeClockOutMenu = createAsyncThunk(
    "markAttandance/closeClockOutMenu",
    async (data) => {

        return true;
        if (true) {
            return data;
        } else {
            throw Error;
        }

    }
);



export const submitFormAnswer = createAsyncThunk(
    "markAttandance/submitFormAnswer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/submit-form-answer`;

        const token = localStorage.getItem('token')
        if(!token){
            alert("No Token Found, Please Login")
        }

        const config = {
            headers: {
                authorization: 'Bearer ' + token,
                'X-emCode': localStorage.getItem('emCode')

            }
        }

        const response = await axios.post(endPoint, formData, config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const getMoreShop = createAsyncThunk(
    "markAttandance/getMoreShop",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-store`;

        const token = localStorage.getItem('token')
        if(!token){
            alert("No Token Found, Please Login")
        }

        const config = {
            headers: {
                Authorization: 'Bearer ' + token,
                'X-emCode': localStorage.getItem('emCode')

            }
        }

        const response = await axios.post(endPoint, formData, config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const editFormAnswer = createAsyncThunk(
    "markAttandance/editFormAnswer",
    async (formData) => {


        let array = null;

        let endPoint = `${apiConfig.adminApi}/form/edit-form-answer`;


        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
                'X-emCode': localStorage.getItem('emCode')

            }
        }

        const response = await axios.post(endPoint, formData, config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const markAttandanceSlice = createSlice({
    name: "markAttandance",
    initialState,
    extraReducers: {

        [initState.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            return {
                ...state,
                formInformation: data.form ? data.form : null,
                selectedFormId: data.formId ? data.formId : null,
                formGroupTask: data.group ? data.group : [],
                ruleArray: data.formRule ? data.formRule : [],
                formSchemaTask: data.schema ? data.schema : [],
            }
        },
        [getFormAnswerList.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            return {
                ...state,
                selectedAnswer: data.clockIndData,
                // attandanceDataSql: data.sqlData ? data.sqlData : null

            }
        },
        [shareLocationReducer.pending]: (state, action) => {
            const data = action.payload ? action.payload : null

            return {
                ...state,
                shareLocationReload: true

            }
        },
        [shareLocationReducer.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            console.log('shereLocation',data)
            const locationShareTimea= new Date();

            return {
                ...state,
                shareLocation: data.shareLocation ? true : false,
                shopData: data.showData ? data.showData : [],
                locationData: data.locationData ? data.locationData : null,
                shareLocationReload: false,
                locationShareTime:locationShareTimea,
                attandance:data.attandance?data.attandance:[],
                userData:data.userData?data.userData:null,
                locationError:data.error?data.error:null

            }
        },
        [removeStateReducer.pending]: (state, action) => {
            const data = action.payload ? action.payload : null

            return {
                ...state,
                shareLocationReload: true

            }
        },
        [removeStateReducer.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
           
            return {
                ...state,
                shareLocation: false,
                shopData:  null,
                locationData: null,
                shareLocationReload: false,
                locationShareTime:null,
                attandance:[],
                userData:null,
                locationError:null

            }
        },
        
        [closeClockingModel.fulfilled]: (state, action) => {

            return {
                ...state,
                viewClockInData: false
            }
        },

        [submitFormAnswer.pending]: (state, action) => {
            const data = action.payload ? action.payload : null

            return {
                ...state,
            }
        },


        [submitFormAnswer.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null

            return {
                ...state,
                viewClockInData: true

            }
        },
        [changeDataEditAction.pending]: (state, action) => {

            return {
                ...state,

            }
        },
        [changeDataEditAction.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            return {
                ...state,
                isEnableEdit: data.enableEdit ? true : false,
                formDataSetEdit: data.data ? data.data : {},
            }
        },
        [editFormAnswer.pending]: (state, action) => {

            return {
                ...state,

            }
        },
        [editFormAnswer.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            // let answer = data.data.answer.data.database.main

            return {
                ...state,
                answer: data?.data?.answer ? data.data.answer : null,
                viewClockoutMenu: true
            }
        },
        [closeClockOutMenu.fulfilled]: (state, action) => {

            return {
                ...state,
                viewClockoutMenu: false
            }
        },
        [changeDataReducer.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null


           let error = false
            if (data.err.length != 0) {
                error = true
            } else {
                error = false
            }

            const dataset = data?.data?.database?.main ? data.data.database.main : null
            let dataWarningArray = []
            let dataErrorArray = []
            if (dataset) {
                for (const rule of data.ruleArray) {

                    let similerities = 0
                    for (const ruleObj of rule.rules) {
                        const field = ruleObj.field ? ruleObj.field : null
                        const value = ruleObj.answer ? ruleObj.answer : null
                        const action = ruleObj.action ? ruleObj.action : null
                        if (field && value) {
                            if (dataset[field]) {
                                if (!action) {
                                    if (dataset[field] == value) {
                                        similerities++
                                    }
                                } else if (action == 'equal') {
                                    if (dataset[field] == value) {
                                        similerities++
                                    }
                                }
                                else if (action === 'notEqual') {
                                    if (dataset[field] != value) {
                                        similerities++
                                    }
                                }
                                else if (action === 'equalLarger') {
                                    if (dataset[field] >= value) {
                                        similerities++
                                    }
                                }
                                else if (action === 'equalSmaller') {
                                    if (dataset[field] <= value) {
                                        similerities++
                                    }
                                } else if (action === 'larger') {
                                    if (dataset[field] > value) {
                                        similerities++
                                    }
                                } else if (action === 'smaller') {
                                    if (dataset[field] < value) {
                                        similerities++
                                    }
                                } else if (action === 'notempty') {
                                    if (dataset[field]) {
                                        similerities++
                                    }
                                } else if (action === 'empty') {
                                    if (!dataset[field]) {
                                        similerities++
                                    }
                                }

                            }
                        }

                    }
                    if (rule.rules.length === similerities) {
                        if (rule.type == 'warning') {
                            dataWarningArray.push(rule)
                        } else {
                            dataErrorArray.push(rule)
                        }
                    }



                }
            }

            if (dataErrorArray.length != 0 || data.err.length != 0) {
                error = true
            } else {
                error = false
            }

            return {
                ...state,
                addbuttonDisable: error,
                warningList: dataWarningArray,
                errorList: dataErrorArray,
                formDataSetTask: data.data ? data.data : null

            }

        },




    },
});

export default markAttandanceSlice.reducer;


